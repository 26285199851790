@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&family=Urbanist:wght@300;400;500;600;700&display=swap");

html {
  background: #fafafa;
}
body {
  font-family: "Urbanist", sans-serif;
}
.overflow-style-none::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.overflow-style-none {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
/* width */
.scroll-style-1::-webkit-scrollbar {
  width: 2px;
}

/* Track */
.scroll-style-1::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.text-success-500{
  color: green
}

.text-orange-500 {
  color: #FFA500; /* or any other appropriate shade of orange */
}

.bg-orange-500 {
  background-color: #FFA500;
}

.bg-red-500 {
  background-color: #f56565;
}

.bg-green-500 {
  background-color: #48bb78;
}

.bg-purple-500{
  background-color: lightcoral;
}

.bg-blue-500 {
  background-color: #159df7;
}

.bg-orange-200 {
  background-color: #fff7ed;
}

.gradient-orange-bg {
  background-image: linear-gradient(135deg, #fb923c, #c2410c);

}

.bnk {
  filter: brightness(0) invert(1);
}


/* Handle */
.scroll-style-1::-webkit-scrollbar-thumb {
  background: #8e8e8e;
}

/* Handle on hover */
.scroll-style-1::-webkit-scrollbar-thumb:hover {
  background: #8e8e8e;
}

.layout-wrapper .sidebar-wrapper {
  left: -308px;
  transition: left 0.5s ease-in-out;
}
.layout-wrapper.active .sidebar-wrapper {
  left: 0;
}
.layout-wrapper .sidebar-wrapper-collapse {
  left: 0;
  transition: left 0.5s ease-in-out;
}
.layout-wrapper.active .sidebar-wrapper-collapse {
  left: -120px;
}
.layout-wrapper .body-wrapper {
  /*margin-left: 96px;*/
  transition: margin-left 0.5s ease-in-out;
}
.layout-wrapper.active .body-wrapper {
  margin-left: 208px;
}
.upgrade-wrapper .upgrade-banner {
  background-repeat: no-repeat;
  background-size: cover;
}
.layout-wrapper.active .body-wrapper .drawer-btn {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}
.layout-wrapper .body-wrapper .drawer-btn {
  opacity: 100;
  transition: opacity 0.5s ease-in-out;
}
.layout-wrapper.active .body-wrapper .header-wrapper {
  width: calc(100% - 308px);
  transition: width 0.5s ease-in-out;
}
.layout-wrapper .body-wrapper .header-wrapper {
  width: calc(100% - 96px);
  transition: width 0.5s ease-in-out;
}
.progess-bar .bonus-per .bonus-outer {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  padding: 10px;
  box-shadow: 100px 100px 100px 100px #e4e4e4 inset;
}
.progess-bar .bonus-per .bonus-inner {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
}
.progess-bar .bonus-per svg {
  position: absolute;
  left: 0;
  top: 0;
}
.progess-bar .bonus-per svg circle {
  fill: none;
  stroke: #E32142;
  stroke-width: 9px;
  stroke-dasharray: 215;
}
.card-slider .slick-dots {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
.card-slider .slick-dots button {
  width: 10px;
  height: 4px;
  border-radius: 50px;
  text-indent: 1000px;
  overflow: hidden;
  background: #a0aec0;
  margin-right: 4px;
  transition: 1s all ease-in-out;
}
.card-slider .slick-dots .slick-active button {
  width: 24px;
  background: #1a202c;
}
.mini-calender-wrapper .flatpickr-calendar {
  box-shadow: none;
}
.mini-calender-wrapper
  .flatpickr-calendar
  :is(.flatpickr-time, .flatpickr-months) {
  display: none;
}
.mini-calender-wrapper
  :is(.flatpickr-calendar, .flatpickr-innerContainer, .flatpickr-rContainer, .flatpickr-days, .dayContainer) {
  width: 100%;
  min-width: 100%;
}
.mini-calender-wrapper
  .flatpickr-calendar
  .flatpickr-innerContainer
  .flatpickr-rContainer {
  padding: 26px;
  border-radius: 18px;
  background: #f7fafc;
}
.dark .flatpickr-calendar{
  background: #1D1E24;
}

.dark .mini-calender-wrapper
  .flatpickr-calendar
  .flatpickr-innerContainer
  .flatpickr-rContainer {
  background: #23262B;
  border-color: #2A313C;
}
.mini-calender-wrapper
  .flatpickr-calendar
  .flatpickr-innerContainer
  .flatpickr-rContainer
  .flatpickr-weekdays
  .flatpickr-weekdaycontainer
  .flatpickr-weekday {
  color: #b7b7b7;
  font-weight: 600;
  font-size: 14px;
}
.mini-calender-wrapper
  .flatpickr-calendar
  .flatpickr-innerContainer
  .flatpickr-rContainer
  .flatpickr-weekdays {
  margin-bottom: 5px;
}
.dark .mini-calender-wrapper
  .flatpickr-calendar
  .flatpickr-innerContainer
  .flatpickr-rContainer
  .flatpickr-days
  .dayContainer
  .flatpickr-day {
  color: #ffffff;
 
}
.mini-calender-wrapper
  .flatpickr-calendar
  .flatpickr-innerContainer
  .flatpickr-rContainer
  .flatpickr-days
  .dayContainer
  .flatpickr-day {
  color: #000000;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 8px;
}
.mini-calender-wrapper
  .flatpickr-calendar
  .flatpickr-innerContainer
  .flatpickr-rContainer
  .flatpickr-days
  .dayContainer
  .flatpickr-day.prevMonthDay {
  color: #b7b7b7;
  font-weight: 400;
}
.mini-calender-wrapper
  .flatpickr-calendar
  .flatpickr-innerContainer
  .flatpickr-rContainer
  .flatpickr-days
  .dayContainer
  .flatpickr-day.nextMonthDay {
  color: #b7b7b7;
  font-weight: 400;
}
.mini-calender-wrapper
  .flatpickr-calendar
  .flatpickr-innerContainer
  .flatpickr-rContainer
  .flatpickr-days
  .dayContainer
  .flatpickr-day.today,
.mini-calender-wrapper
  .flatpickr-calendar
  .flatpickr-innerContainer
  .flatpickr-rContainer
  .flatpickr-days
  .dayContainer
  .flatpickr-day.today.selected {
  background: #E32142;
  color: #ffffff;
  border: none;
  border-radius: 8px;
}
.mini-calender-wrapper
  .flatpickr-calendar
  .flatpickr-innerContainer
  .flatpickr-rContainer
  .flatpickr-days
  .dayContainer
  .flatpickr-day.selected {
  background: none;
  border: 1px solid #E32142;
  border-radius: 8px;
}
.dark .fc-daygrid-dot-event .fc-event-title{
  color: white;
}

canvas {
  position: relative;
  z-index: 1;
}

/* Modal */
/* Hide scrollbar */
body.modal-open {
  overflow: hidden;
}

.modal {
  z-index: 9999;
}

.modal-overlay {
  z-index: -1;
}

/* Settings Tab */
.tab {
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}
.tab.active {
  background-color: #f7fafc;
  color: #1e293b;
}
.dark .tab.active {
  background-color: #23262B;
}

.dark .tab .tab-icon {
  background-color: #23262B;
  color: white;
}
.tab.active .tab-icon {
  background-color: #E32142;
  color: white;
}
.tab-pane {
  display: none;
}
.tab-pane.active {
  display: block;
}

/* Accordion */
.accordion-content {
  transition: max-height 0.3s ease-out, padding 0.3s ease;
}

/* Custom Quill Editor */

.custom-quill .ql-toolbar.ql-snow {
  background-color: #fafafa;
  border: 1px solid #e2e8f0;
  color: white;
  padding: 16px 24px;
  border-radius: 8px 8px 0 0;
}
.dark .custom-quill .ql-toolbar.ql-snow {
  background-color: #E2E8F0;
  border: #2A313C;
}

.custom-quill .ql-toolbar.ql-snow .ql-formats {
  color: hsl(0, 0%, 100%);
}
.ql-container.ql-snow {
  border: 1px solid #e2e8f0;
}

/* Quill Two */
.custom-quill-2 .ql-container.ql-snow {
  background-color: #ffffff;
  border-radius: 8px 8px 0 0;
  border-bottom: 0;
}
.dark .custom-quill .ql-container.ql-snow {
  background-color: #1D1E24;
  border-color: #2A313C;
}
.dark .custom-quill-2 .ql-container.ql-snow {
  background-color: #1D1E24;
  border-color: #2A313C;
}

.custom-quill-2 .ql-toolbar.ql-snow {
  padding: 16px 20px;
  background: #fafafa;
  border-radius: 0 0 8px 8px;
  border: 1px solid #e2e8f0;
}
.dark .custom-quill-2 .ql-toolbar.ql-snow {
  background: #1D1E24;
  border-color: #2A313C;
}
.dark .ql-editor{
  color: white;
}
/* for calender */

.fc .fc-daygrid .fc-non-business {
  background-color: white ;
}
.dark .fc .fc-daygrid .fc-non-business {
  background-color: #23262B ;
}

.fc .fc-col-header-cell .fc-col-header-cell-cushion {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #E32142;
}

.fc-day-sun .fc-col-header-cell-cushion,
.fc-day-sun a.fc-daygrid-day-number {
  color: #ff4747 !important;
}

.fc-day-sat,
.fc-day-sat a.fc-daygrid-day-number {
  color: #ff4747 !important;
}

.fc-button-group button.fc-dayGridMonth-button.fc-button.fc-button-primary,
.fc-button-group button.fc-timeGridWeek-button.fc-button.fc-button-primary,
.fc-button-group button.fc-timeGridDay-button.fc-button.fc-button-primary,
.fc-button-group button.fc-listMonth-button.fc-button.fc-button-primary {
  display: none !important;
}

button.fc-button.fc-button-primary.fc-next-button,
button.fc-button.fc-button-primary.fc-prev-button {
  border: 1px solid gray;
  padding: 10px 5px;
  border-radius: 5px;
  margin-right: 2px;
  outline: none;
}

.MiddletitleButton {
  display: flex;
}
.fc-toolbar-title {
  color: #1a202c ;
  font-size: 18px !important;
  font-weight: bold !important;
}
.dark .fc-toolbar-title {
  color: white;
}
.fc-header-toolbar {
  margin-bottom: 24px !important;
}
.fc td,
.fc th {
  border-style: none !important;
}
.fc-scrollgrid {
  padding-top: 20px !important ;
  border-top: 1px solid #e4f2ff !important ;
}
.dark .fc-scrollgrid {
  border-top: 1px solid #2A313C!important ;
}

.fc-scrollgrid td:last-of-type {
  border-right: none !important;
}
.fc-day:first-child > div:first-child {
  position: relative;
}
#calendar td {
  vertical-align: middle !important;
  border: 1px solid #000;
}

.fc {
  text-align: center !important;
}

.fc td {
  padding: 0;
  vertical-align: middle !important;
}

.fc-day .fc-daygrid-day-frame {
  position: relative !important;
}
.fc-day .fc-daygrid-day-frame .fc-daygrid-day-number {
  position: absolute !important;
  top: 50% !important;
  left: 0px !important;
  right: 0px !important;
  text-align: center !important;
  margin-top: -0.5em !important;
  line-height: 1em !important;
  font-weight: 500;
  font-size: 18px;
  color: #2f3032;
}
.dark .fc-day .fc-daygrid-day-frame .fc-daygrid-day-number {
  color: white;
}

.fc .fc-daygrid .fc-day-today .fc-daygrid-day-frame {
  border: 2px solid #b7ffd1 !important;
  background: none !important;
}
.fc .fc-day-today {
  background: none !important;
}
/* .fc .fc-timegrid .fc-daygrid-day{
  background: none !important;
} */
.fc .fc-timegrid .fc-timegrid-slot.fc-timegrid-slot-lane {
  border: 1px solid #cbd5e0 !important;
}
.fc .fc-non-business {
  background: none !important;
}
.fc .fc-daygrid .fc-timegrid-col {
  background: none !important;
  border: none !important;
}
.fc .fc-daygrid .fc-day-today .fc-daygrid-day-frame {
  border-radius: 6px;
}
.fc .fc-scrollgrid.fc-scrollgrid-liquid {
  border: none;
}
.fc .fc-view-harness{
  width: 100% !important;
  height: 800px !important;
}
.fc .fc-scrollgrid-sync-table{
  width: 100% !important;
  height: 100% !important;
}

.fc table.fc-col-header {
  width: 100% !important;
}
.fc .fc-daygrid-body.fc-daygrid-body-unbalanced {
  width: 100% !important;
  height: 100% !important;
}

:is(.sidebar-wrapper, .sidebar-wrapper-collapse)
  .sidebar-body
  .item-wrapper
  ul
  li.item,
:is(.sidebar-wrapper, .sidebar-wrapper-collapse)
  .sidebar-body
  .item-wrapper
  ul
  li.item
  .item-ico
  svg
  .path-1,
:is(.sidebar-wrapper, .sidebar-wrapper-collapse)
  .sidebar-body
  .item-wrapper
  ul
  li.item
  .item-ico
  svg
  .path-2 {
  transition: all 0.3s ease-in-out;
}

:is(.sidebar-wrapper, .sidebar-wrapper-collapse)
  .sidebar-body
  .item-wrapper
  ul
  li.item:hover
  .item-ico
  svg
  .path-1,
:is(.sidebar-wrapper, .sidebar-wrapper-collapse)
  .sidebar-body
  .item-wrapper
  ul
  li.item:hover {
  fill: #E32142;
  color: #E32142;
}
:is(.sidebar-wrapper, .sidebar-wrapper-collapse)
  .sidebar-body
  .item-wrapper
  ul
  li.item:hover
  .item-ico
  svg
  .path-2 {
  fill: #b7ffd1;
}
.sidebar-wrapper .sidebar-body .nav-wrapper ul li.item .sub-menu {
  display: none;
  animation: tabFade 1s;
}

.sidebar-wrapper .sidebar-body .nav-wrapper ul li.item .sub-menu.active {
  display: block;
}
@keyframes tabFade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.sidebar-wrapper-collapse .sidebar-body .nav-wrapper ul li.item {
  position: relative;
}

.sidebar-wrapper-collapse .sidebar-body .nav-wrapper ul li.item .sub-menu {
  position: absolute;
  left: 100%;
  top: 0;
  z-index: 10;
  display: none;
}
.sidebar-wrapper-collapse
  .sidebar-body
  .nav-wrapper
  ul
  li.item:hover
  .sub-menu {
  display: block;
}

/* TODO: ===============RESPONSIVE */
:is(.dark .sidebar-wrapper, .dark .sidebar-wrapper-collapse)
.sidebar-body
.item-wrapper
ul
li.item
.item-ico
svg
.path-1,
:is(.dark .sidebar-wrapper, .dark .sidebar-wrapper-collapse)
.sidebar-body
.item-wrapper
ul
li.item {
  fill: #FFFFFF;
  color: #FFFFFF;
}
:is(.dark .sidebar-wrapper, .dark .sidebar-wrapper-collapse)
.sidebar-body
.item-wrapper
ul
li.item:hover
.item-ico
svg
.path-1,
:is(.dark .sidebar-wrapper, .dark .sidebar-wrapper-collapse)
.sidebar-body
.item-wrapper
ul
li.item:hover {
  fill: #27DA68;
  color: #27DA68;
}
:is(.dark .sidebar-wrapper, .dark .sidebar-wrapper-collapse)
.sidebar-body
.item-wrapper
ul
li.item:hover
.item-ico
svg
.path-2 {
  fill: #16A34A;
}
@media (max-width: 1536px) {
}
@media (max-width: 1280px) {
}
@media (max-width: 1024px) {
  .fc .fc-view-harness{
    width: 100% !important;
    height: 600px !important;
  }
  .layout-wrapper.active .sidebar-wrapper-collapse {
    left: 0;
  }
  .layout-wrapper.active .body-wrapper {
    margin-left: 0;
  }
  .layout-wrapper.active .body-wrapper .header-wrapper {
    width: calc(100% - 96px);
    transition: all 0.5s ease-in-out;
  }
}

@media (max-width: 768px) {
}

@media (max-width: 640px) {
  .fc .fc-view-harness{
    width: 100% !important;
    height: 400px !important;
  }
}
@media (max-width: 426px) {
  .layout-wrapper.active .body-wrapper {
    margin-left: 0;
  }
  .layout-wrapper.active .body-wrapper .drawer-btn {
    opacity: 1;
  }
  .layout-wrapper .body-wrapper {
    margin-left: 0;
  }
  .layout-wrapper.active .sidebar-wrapper {
    left: -308px;
  }
  .layout-wrapper .sidebar-wrapper {
    left: 0;
  }
  .layout-wrapper .sidebar-wrapper + .aside-overlay {
    left: 0;
  }
  .layout-wrapper.active .sidebar-wrapper + .aside-overlay {
    left: -100%;
  }
}

/* TODO: ===============RESPONSIVE END */

.dark .jvm-container{
  background-color: #23262B!important;
  border-color: #2A313C;
}




/******************* my css ********************/



.lds-ring {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;

}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  margin: auto;
  border: 2px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #84ff00 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


.tooltip-trigger {
  position: relative;
  cursor: pointer;
}

.tooltip-trigger:hover::before {
  content: attr(title);
  position: absolute;
  top: -30px;
  left: 0;
  padding: 5px;
  background-color: #000;
  color: #fff;
  border-radius: 5px;
  white-space: nowrap;
}

.tooltip-trigger.dark:hover::before {
  content: attr(title);
  position: absolute;
  top: -50px;
  left: 0;
  padding: 5px;
  background-color: #fff;
  color: #000;
  border-radius: 5px;
  white-space: nowrap;
}

.badge-status{
  padding: 2px 6px;
  border-radius: 1px;
  border: 1px solid rgba(0,0,0,0.1);
  text-transform: uppercase;
  font-size: 13.2px;
  font-weight: 500;
  letter-spacing: 1px;
  border-radius: 2px;

}


.btn-gradient{
  background-color: rgb(33, 198, 94);
  padding: 4px 10px;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 2px 0px;
  border: none;
  border-radius: 4px;
  color: #fff;
}


.invoice-box{
  background-color: rgb(211, 240, 240);
  padding: 20px 30px;
  width: 100%;
}


.invoice-box p {
  color: red;
  font-weight: 600;
}

.invoice-box ul{
  margin-top: 20px;
}

.invoice-box ul li{
  font-size: 15px;
  margin-top: 10px;
  font-weight: lighter;
  color: #000;
}

main {
  min-height: 100vh;
}




.login-wrap {
  display: flex;
  height: 100vh;

  font-family: 'Inter', 'Helvetica', 'Arial', 'system-ui', '-apple-system' !important;
  position: relative;
}

.login-main {
  padding: 3rem;
  display: flex;
  justify-content: center;
  width: 66.5%;
}

.logo-wrap {
  height: 1.5rem;
  position: absolute;
  z-index: 50;
  left: 1rem;
  top: -1rem;
}


.login-form-wrap {

  width: 600px;
  margin: auto;
  flex-direction: column;
}

.bg-chalk {
  background-color: rgb(246 247 245/1);
  width: 33.5%;
  background: url(../../../public/admin.avif);
  background-size: cover;
  background-position: center;
}




.login-heading {
  font-size: 48px;
  line-height: 1.2;
  margin-bottom: 3rem;
  letter-spacing: -2px;
  font-weight: 900 !important;
  text-align: center;

}

.field {
  overflow: hidden;
  width: 100%;
  background-color: #F6F7F5;
  height: 50px;
  display: flex;
  border-radius: 10px;
  align-items: center;
  padding-left: 18px;
  color: rgb(103 107 95/1);
  margin-bottom: 0.5rem;
  position: relative;
  border: 2px solid transparent;
}


.field:hover {
  border: 2px solid #ccc;
}


.field input {
  color: rgb(103 107 95/1);
  border: none;
  outline: none;
  background: transparent;
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
  line-height: 48px;
  transition-duration: 75ms;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
}


.field:focus-within {
  border: 2px solid #000;
}



/* Basic button styles */
.login-page-btn {
  position: relative;
  transition: all 0.75s ease-out;
  width: 100%;
  height: 3rem;
  border-radius: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  background-color: rgb(242, 101, 124);
  color: #fff;
  border: none;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: -.32px;
  margin-top: 50px;

}

/* .login-btn text styles */
.login-page-btn span {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .login-page-btn text block styles */
.login-page-btn span span {
  display: block;
  font-weight: 600;
  font-size: 1rem;
  /* You may adjust the font size as needed */
}


.forgot {
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(129 41 217/1);
  padding-top: 2rem;

}

.under {
  text-decoration: underline;
  color: rgb(129 41 217/1);

}


.recap {
  height: 15vh;
  display: flex;
  align-items: end;
  justify-content: center;
  font-family: "Link Sans", Arial, sans-serif;
  color: rgb(103 107 95/1);
  font-size: 13px;
  line-height: 1.5;
  text-align: center;
}

.dd {
  font-family: "Link Sans", Arial, sans-serif;
  color: rgb(103 107 95/1);
  font-size: 14px;
  line-height: 1.5;
  text-align: center;
}


.lomda {
  height: 0;
  overflow: hidden;
  width: 0;
}

.lomda + label {
  background: #f15d22;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: "Rubik", sans-serif;
  font-size: inherit;
  font-weight: 500;
  margin-bottom: 1rem;
  outline: none;
  padding: 1rem 50px;
  position: relative;
  transition: all 0.3s;
  vertical-align: middle;
}
.lomda + label:hover {
  background-color: #d3460d;
}
.lomda + label.btn-1 {
  background-color: #f79159;
  box-shadow: 0 6px #f57128;
  transition: none;
}



.lomda + label.btn-2 {
  background-color: #497f42;
  border-radius: 50px;
  overflow: hidden;
}
.lomda + label.btn-2::before {
  color: #fff;
  content: "";
  font-family: "Font Awesome 5 Pro";
  font-size: 100%;
  height: 100%;
  right: 130%;
  line-height: 3.3;
  position: absolute;
  top: 0px;
  transition: all 0.3s;
}
.lomda + label.btn-2:hover {
  background-color: #497f42;
}
.lomda + label.btn-2:hover::before {
  right: 75%;
}